/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Footer from "./footer"
import Header from "./header"
import PageTransition from "gatsby-plugin-page-transitions"
import ThemeContext from "../hooks/ThemeContext"

const Layout = ({ children }) => {
  const pathname = typeof window === "object" ? window.location.pathname : null
  //  console.log("window.location", window.location)
  //  console.log("pathname", pathname)
  let theLang = pathname?.indexOf("/cz/") > -1 || (pathname?.indexOf("/cz") > -1 && pathname?.length === 3) ? "cz" : "en"
  return (
    <ThemeContext.Provider value={theLang}>
      <PageTransition transitionTime={500}>
        <div id="page" className="site">
          {/* <div className="preloading"></div> */}
          <Header />
          <div className="site-content-contain">
            <div id="content" className="site-content">
              <main id="main" className="site-main" role="main">
                {children}
              </main>
            </div>
          </div>
          <Footer />
        </div>
      </PageTransition>
    </ThemeContext.Provider>
  )
}

export default Layout
