import React from "react"
import spriteSVG from "../sprite/sprite-build.svg"
import { Link } from "gatsby"

export default props => {

  return (
    <>
      <footer className="c-footer">
        <div className="o-container">
          <div className="u-mb-x2 u-mb-x6@md">
            <Link to="/" className="u-mr-x2 __scroll-to"><svg className="u-fill-white u-width-x5" viewBox="0 0 332 217"><use xlinkHref={spriteSVG + "#LogoBig"}></use></svg><span className="o-dictate">Go to Home</span></Link>
          </div>

          <div className="c-footer__inner">


            <div className="c-footer__left">
              <ul className="o-list">
                <li className="o-list__item"><Link to="/#pitch" className="c-footer__link">Short Pitch</Link></li>
                <li className="o-list__item"><Link to="/#use-cases" className="c-footer__link">Use Cases</Link></li>
                <li className="o-list__item"><Link to="/#blog" className="c-footer__link">Latest blog posts</Link></li>
                <li className="o-list__item"><a href="https://docs.pad.tech/" className="c-footer__link"><svg className="u-fill-white u-width-x2 u-mr" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconGitBook"}></use></svg>Developer Docs</a></li>
              </ul>
              <ul className="o-list">
                <li className="o-list__item"><Link to="/tech" className="c-footer__link">Technology</Link></li>
                <li className="o-list__item"><Link to="/tech#roadmap" className="c-footer__link">Product Roadmap</Link></li>
                <li className="o-list__item"><Link to="/blog" className="c-footer__link">Blog</Link></li>
              </ul>
              <ul className="o-list">
                <li className="o-list__item"><Link to="/privacy-policy" className="c-footer__link">Privacy Policy</Link></li>
                <li className="o-list__item"><Link to="/cookie-policy" className="c-footer__link">Cookie Policy</Link></li>
              </ul>
            </div>
            {/* <div className="c-footer__right">
              <p>Address<br />Street<br />POSTCODE<br />City<br />Country</p>
            </div> */}
          </div>
        
        </div>
        
        <div className="c-footer__address-bar">
          <div className="o-container">
            <p>SW7 Asset Management (Singapore) Pte Ltd, One Marina Boulevard #28-00, Singapore 018989</p>
          </div>
        </div>

      </footer>
    </>
  )
}
