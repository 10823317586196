import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
// import ThemeContext from "../hooks/ThemeContext"
import spriteSVG from "../sprite/sprite-build.svg"


export default props => {
  // const [menuOpen, setmenuOpen] = useState(false)
  // const toggleMenuKey = ev => {
    //   // ev.preventDefault()
    //   if (ev.keyCode === 13) {
      //     //      console.log("Enter pressed")
      //     setmenuOpen(!menuOpen)
      //     ev.currentTarget.classList.toggle("open")
      //   }
  // }
  // const toggleMenu = ev => {
    //   setmenuOpen(!menuOpen)
    //   ev.currentTarget.classList.toggle("open")
    // }
    
  const [showCookieBar, setShowCookieBar] = useState(true)
  const setCookieBar = ev => {
    document.getElementsByClassName("c-cookie-bar")[0].remove()
    typeof window === "object" && window.localStorage && window.localStorage.setItem("accept-cookies", Date.now())
  }

  useEffect(() => {
    const value = typeof window === "object" && window.localStorage && window.localStorage.getItem("accept-cookies") !== null ? false : true
    setShowCookieBar(value)
  }, [])

  console.log(showCookieBar);
  


  return (

    <>
      <header id="masthead" className="c-header"  role="banner">
        <div className="c-header__inner o-container o-container--nav">
          <div className="c-header__left">
      
            <Link to="/" className="u-mr-x2">
              <svg className="u-width-x5" viewBox="0 0 332 217"><use xlinkHref={spriteSVG + "#LogoBig"}></use></svg>
              <span className="o-dictate">Go to Home</span>
            </Link>
            <nav className="c-nav">
              <label htmlFor="toggle-menu" className="o-dictate">Toggle menu</label>
              <input type="checkbox" id="toggle-menu" name="toggle-menu" />
              <div className="c-hamburger"><span></span><span></span><span></span></div>
              <ul className="c-main-menu">
                <li><Link to="/tech" activeClassName="active">Technology</Link></li>
                <li><Link to="/blog" activeClassName="active">Blog</Link></li>
              </ul>
            </nav>
          </div>
          <div className="c-header__right">
            <a href="https://docs.pad.tech/" className="u-ai-center u-flex" target="_blank" rel="noopener noreferrer">
              <svg className="u-fill-white u-width-x3 u-mr" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconGitBook"}></use></svg>
              <span>Developer Docs</span>
            </a>
          </div>
        </div>
      </header>
      {
        showCookieBar &&
        <div className="c-cookie-bar">
        <div className="o-container">
          <p className="o-type-5 u-weight-bold">Your privacy</p>
          <div className="c-cookie-bar__wrap">
            <div className="c-cookie-bar__text">
              <div className="o-type-2">
                <p>We use cookies to improve you experience on our website. To find out more, read our updated <Link to="/privacy-policy">privacy policy</Link> and <Link to="/cookie-policy">cookie policy</Link>.</p>
              </div>
            </div>
            <div>
              <button className="c-button" onClick={() => setCookieBar()}>OK</button>
            </div>
          </div>
        </div>
      </div>}

    </>


    
  )
}
